import React from 'react';
import {Typography, Row, Col, Card} from 'antd';

import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import MainTitle from "../components/MainTitle";
import {FaMedal} from "react-icons/fa";
import {FiEye} from "react-icons/fi"
import {TbTargetArrow} from "react-icons/tb"
import {RiCustomerService2Line} from "react-icons/ri"
const {Title, Text} = Typography;
const {Meta} = Card;
const About = () => {
  return (
    <div id="about">
      <div className="main"/>
      <MainTitle title={"אודות"} subtitle={"אודות"}/>
      <div className="about-container">
        <div className="about">
          <div className="upper">
            <div className='right'>
              <Title className="title">אודות</Title>
              <Title level={3}>מתן סולומון - פתרונות ביטוח ופיננסים</Title>
              <Text style={{fontSize: 18}}>
                בן 33, נשוי למאי ואבא לשניים.<br/>
                בעל ניסיון של למעלה מעשור בתחום הביטוח והפיננסים.<br/>
                מחזיק ברישיון סוכן ביטוח פנסיוני ואלמנטרי מטעם משרד האוצר וכן בתעודת יועץ משכנתאות.<br/>
                פועל בתחום הביטוח, השקעות והפיננסים.<br/>
                כמו כן מכהן כחבר בית הדין של לשכת סוכני הביטוח.<br/>
              </Text>
            </div>
            <div className='left'>
              <Row>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<FiEye size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="שקיפות" description='שקיפות ונגישות תמידית למידע מעודכן, בכל זמן או דרך בה בוחר הלקוח/ה'/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<FaMedal size={50} style={{marginTop:10}}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="מחויבות" description='החברה פועלת כגוף פרטי עצמאי שאינו שייך לבנק, בית השקעות, מנהל תיקים או ברוקר ומחוייבת אך ורק ללקוחותיה'/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<TbTargetArrow size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="מקצועיות" description="איכויותיה המקצועית של החברה נובעות מהיותה מנוהלת על ידי כלכלנים, יועצים פיננסים ואנשי שוק הון, מהמובילים בתחום"/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<RiCustomerService2Line size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="שירותיות" description="אני מאמין שתכנון פיננסי איכותי צריך להיות מבוסס על דיאלוג רציף בין הלקוח לבין הסכון הפיננסי, על מנת שיתאים לצרכים המתפתחים של הלקוח ולתנאי השוק המשתנים"/>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className="left">
            <Title className="title">השירותים שלי</Title>
            <Text style={{fontSize: 18}}>
              אני מציע ליווי אישי ושירות בוטיק ללקוח המשרד  עם מעטפת מקצועית מלאה בתחומי הביטוח, פיננסים, השקעות, תכנון פנסיוני, ייעוץ המשכנתאות ועוד.<br/>
              השירות שלי מתאפיין במענה מהיר, מקצועי ובלתי מתפשר.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>היתרון בלהיות לקוח אצלי:</b><br/>
              נהנה מכוח מיקוח משמעותי מול הגופים המוסדיים, מה שמאפשר לשמור על האינטרסים של לקוחותי מול כל חברות הביטוח ובתי ההשקעות בישראל.
              <br/><br/>

               <b style={{fontSize: 18, fontWeight: 'bolder'}}>הגישה שלי:</b><br/>
              המוטו הוא BEST ADVISE –  תכנון חכם ומותאם אישית לכל לקוח, לצד מחויבות מלאה להיות שם עבורכם ברגע האמת, כגורם יציב ואמין הנלחם למענכם.
              <br/><br/>
               <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה תקבלו אצלי?</b><br/>

              &emsp;✅ התאמה אישית ומקצועית של התיק הפיננסי והביטוחי.<br/>
              &emsp;✅ חוויית שירות יוצאת דופן – עניינית, חברית ומקצועית.<br/>
              &emsp;✅ זמינות מלאה – משרדי פועל בשעות העבודה, אך זמין בנייד 24/7 לכל מקרה דחוף.<br/>
              &emsp;✅ שירות אנושי וליווי צמוד – ללא בוטים וללא המתנות ארוכות.<br/>
              &emsp;✅ מינימום בירוקרטיה –מטפל בכל הניירת כדי להבטיח שתקבלו את מלוא הזכויות שלכם, ואף מעבר לכך.<br/>
              <br/>
              אני כאן כדי להעניק לכם שקט נפשי ולוודא שאתם בידיים הטובות ביותר!
              <br/>
            </Text>
          </div>
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default About;
