import React from 'react';
import {Typography, Row, Col, Space, List} from 'antd';
import {FacebookOutlined,  WhatsAppOutlined} from '@ant-design/icons';

import Logo from '../../components/Header/Logo';
import LogoTitle from "../../components/Header/LogoTitle";

const {Title, Text} = Typography;

const MyFooter = () => {
  const siteNavigationList = [
    {title: 'ראשי', link: '/'},
    {title: 'אודות', link: '/about'},
    {title: 'השירותים שלי', link: '/about'},
    {title: 'יצירת קשר', link: '/contactUsPage'},
  ];

  return (
    <div id="footer">
      <Row gutter={32}>
        <Col md={8} xs={24} className="our-service">
          <Title level={4}>השירותים שלי</Title>
          <Text>

            אני מציע ליווי אישי ושירות בוטיק ללקוח המשרד  עם מעטפת מקצועית מלאה בתחומי הביטוח, פיננסים, השקעות, תכנון פנסיוני, ייעוץ המשכנתאות ועוד.<br/>
            השירות שלי מתאפיין במענה מהיר, מקצועי ובלתי מתפשר.<br/><br/>

            <b style={{fontSize: 18, fontWeight: 'bolder'}}>היתרון בלהיות לקוח אצלי:</b><br/>
            נהנה מכוח מיקוח משמעותי מול הגופים המוסדיים, מה שמאפשר לשמור על האינטרסים של לקוחותי מול כל חברות הביטוח ובתי ההשקעות בישראל.
            <br/><br/>

            <b style={{fontSize: 18, fontWeight: 'bolder'}}>הגישה שלי:</b><br/>
            המוטו הוא BEST ADVISE –  תכנון חכם ומותאם אישית לכל לקוח, לצד מחויבות מלאה להיות שם עבורכם ברגע האמת, כגורם יציב ואמין הנלחם למענכם.
            <br/><br/>
            <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה תקבלו אצלי?</b><br/>
            &emsp;✅ התאמה אישית ומקצועית של התיק הפיננסי והביטוחי.<br/>
            &emsp;✅ חוויית שירות יוצאת דופן – עניינית, חברית ומקצועית.<br/>
            &emsp;✅ זמינות מלאה – משרדי פועל בשעות העבודה, אך זמין בנייד 24/7 לכל מקרה דחוף.<br/>
            &emsp;✅ שירות אנושי וליווי צמוד – ללא בוטים וללא המתנות ארוכות.<br/>
            &emsp;✅ מינימום בירוקרטיה –מטפל בכל הניירת כדי להבטיח שתקבלו את מלוא הזכויות שלכם, ואף מעבר לכך.<br/>
            <br/>
            אני כאן כדי להעניק לכם שקט נפשי ולוודא שאתם בידיים הטובות ביותר!
            <br/>
          </Text>
        </Col>
        <Col md={2} xs={24}/>
        <Col md={4} xs={24} className="site-navigation">
          <Title level={4}>ניווט באתר</Title>
          <List
            size="small"
            dataSource={siteNavigationList}
            renderItem={(item) =>
              <List.Item>
                <a href={item.link} aria-label={item.title}>{item.title}</a>
              </List.Item>
            }
          />
        </Col>
        <Col md={4} xs={24} className="site-navigation">
          <Title level={4}>פרטי התקשרות</Title>
          <Space direction="vertical" align="start" style={{width: '100%'}}>
            <Text>ההסתדרות 21, חיפה</Text>
            <a href="tel:0548072575" aria-label="טלפון">
              <span className="elementor-icon-list-text"> 054-807-2575</span>
            </a>
            <a href="mailto://matan@solomon-inf.co.il" aria-label="email">
              <span className="elementor-icon-list-text" > matan@solomon-inf.co.il </span>
            </a>
          </Space>
          <Space size="middle" style={{direction: 'ltr', fontSize: 25, padding: 10,}}>
            <a href="https://wa.me/+972548072575" target="_blank" style={{color: '#000'}} rel="noreferrer" title='whatapp' aria-label="whatapp">
              <WhatsAppOutlined/>
            </a>
            <a href="https://www.facebook.com/matansolomonins"  id="facebook"  target="_blank" style={{color: '#000'}} rel="noreferrer" title='facebook' aria-label="facebook">
              <FacebookOutlined/>
            </a>
           {/* <a href="" target="_blank" style={{color: '#000'}}>
              <InstagramOutlined/>
            </a>*/}
          </Space>
        </Col>

        <Col md={6} xs={24} className="contact">
          <Space align="center" style={{width: '100%'}}>
            <Logo/>
            <LogoTitle/>
          </Space>
        </Col>
      </Row>
    </div>
  )
};

export default MyFooter;
