import React, {useEffect} from 'react';
import {Typography} from 'antd';
import {useParams} from 'react-router';
import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import MainTitle from "../components/MainTitle";
import medi from '../assets/agent-assets/matan/medi.png'
import harel from '../assets/agent-assets/matan/harel.png'
import calalbit from '../assets/agent-assets/matan/calalbit.png'

const {Title, Paragraph} = Typography;
const getTitleByID = (id: string | undefined) => {
  switch (id) {
    case '1':
      return 'ביטוח בריאות';
    case '2':
      return 'ביטוח חיים';
    case '3':
      return 'מחלות קשות';
    case '4':
      return 'מדיהו';
    case '5':
      return 'פנסיה';
    case '6':
      return 'גמל';
    case '7':
      return 'קרן השתלמות';
    case '8':
      return 'גמל להשקעה';
    case '9':
      return 'פוליסת חסכון';
    case '10':
      return 'השקעות אלטרנטיוביות';
    case '11':
      return 'ביטוחי רכב ודירה';
    case '12':
      return 'ביטוח נסיעות לחול';
    case '13':
      return 'החזרי מס';
    case '14':
      return 'יעוץ משכנתא';
    case '15':
      return 'יעוץ ותכנון פרישה';
    default:
      return '';
  }
}

const OurServices = () => {
  const {id} = useParams();
  const title = getTitleByID(id);

  useEffect(() => {
    if(![1,2,3,4,5,6,7,8,9,11,12,13,14,15].includes(Number(id))) {
      window.location.href = 'https://www.solomon-inf.co.il/';
    }
  }, [id]);
  return (
    <div id="our-services">
      <div className="main"/>
      <MainTitle title={title} subtitle={title}/>
      <div className="our-services-container">
        <div className="details">
          {id === '1' && <div className="right">
            <Title className="title">ביטוח בריאות – ביטחון רפואי לכם ולמשפחתכם</Title>
            <Title level={4}>הפוליסות הפרטיות מותאמות באופן אישי למבוטח ולצרכיו. כשאתם רוכשים ביטוח בריאות פרטי לכם
              ולבני משפחתכם, אתם יכולים לבחור את המרכיבים המתאימים לכם.</Title><br/>

            <Title className="sub-title" level={3}>✅ ניתוחים וטיפולים מחליפי ניתוח</Title>
            <Paragraph className="sub-description">
              &emsp;• גישה לרופאים מומחים מתוך רשימה מובילה<br/>
              &emsp;• אפשרות לביצוע ניתוחים בארץ ובחו"ל<br/>
              &emsp;               • כיסוי להוצאות נלוות הקשורות לניתוח
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>✅ תרופות מחוץ לסל הבריאות</Title>

            <Paragraph className="sub-description">
              &emsp; • כיסוי לתרופות שאינן כלולות בסל הבריאות ואושרו לשימוש בחו"ל<br/>
              &emsp; • החזר הוצאות עבור טיפולים תרופתיים מתקדמים<br/>
              &emsp; • גישה לטיפולים חדשניים מצילי חיים
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>✅ השתלות וטיפולים מיוחדים בחו"ל</Title>
            <Paragraph className="sub-description">
              &emsp; • כיסוי מלא להוצאות השתלה ללא תקרה<br/>
              &emsp; • כיסוי להוצאות טיפולים מיוחדים בחו"ל<br/>
              &emsp; • ליווי רפואי ומימון תהליך ההשתלה
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>✅ מחלות קשות</Title>
            <Paragraph className="sub-description">
              &emsp; • כיסוי לאבחון וליווי רפואי עבור מחלות קשות<br/>
              &emsp; • פיצוי כספי חד-פעמי בעת גילוי מחלה מתוך רשימה של 43 מחלות ואירועים רפואיים<br/>
              &emsp; • מימון טיפולים רפואיים מתקדמים בארץ ובעולם
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>✅ שירותים אמבולטוריים מתקדמים</Title>
            <Paragraph className="sub-description">
              &emsp; • בדיקות אבחוניות מהירות ומתקדמות<br/>
              &emsp; • ייעוץ רפואי עם מומחים מובילים בישראל<br/>
              &emsp; • טיפולי פיזיותרפיה ושיקום<br/>
              &emsp; • בדיקות מעבדה לאיתור גידולים ממאירים<br/>
              &emsp;      • אביזרים ומכשירים רפואיים הנדרשים בעקבות תאונה
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>✅ כיסוי מורחב להיריון ולילדים</Title>
            <Paragraph className="sub-description">
              &emsp; • סקירות גנטיות לזיהוי מומים מולדים<br/>
              &emsp; • בדיקות טרום לידתיות (מי שפיר, סיסי שליה)<br/>
              &emsp; • בדיקות אולטרסאונד מתקדמות<br/>
              &emsp; • אבחון דידקטי לילדים<br/>
              &emsp; • ייעוץ ואבחון הפרעות קשב וריכוז<br/>
              &emsp; • טיפולים ייחודיים לבעיות התפתחות
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>שומרים על הבריאות שלכם – בכל שלב בחיים</Title>
            <Paragraph className="sub-description">
              ביטוח הבריאות הפרטי מעניק לכם שקט נפשי וגישה מהירה לשירותים רפואיים מתקדמים, כך שתוכלו לקבל את הטיפול הרפואי הטוב ביותר – בדיוק בזמן שאתם זקוקים לו.
            </Paragraph>

          </div>}
          {id === '2' && <div className="right">
            <Title className="title">ביטוח חיים</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>ביטוח חיים – ביטחון כלכלי למשפחה שלך</b><br/>
              ביטוח חיים נועד להבטיח את עתידם הכלכלי של היקרים לך במקרה של פטירה מכל סיבה. הפוליסה מעניקה למוטבים שלך סכום כסף חד-פעמי או קצבה חודשית, בהתאם לצרכים ולתכנון הפיננסי האישי שלך.<br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>למה חשוב לעשות ביטוח חיים?</b><br/>
              &emsp; ✅ שמירה על רמת החיים של המשפחה – מעניק למשפחה יציבות כלכלית, גם במקרה של אובדן הכנסה פתאומי.<br/>
              &emsp; ✅ תכנון אישי וגמיש – סכום הביטוח נקבע בהתאמה אישית על פי צורכי המשפחה, הכנסות והתחייבויות כלכליות.<br/>
              &emsp; ✅ תשלום חד-פעמי או קצבה חודשית – בהתאם להעדפה האישית שלך, ניתן לבחור בין סכום חד-פעמי למוטבים או תשלום חודשי קבוע שיסייע בניהול ההוצאות השוטפות.<br/><br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>למי זה מתאים?</b><br/>
              ביטוח חיים מתאים לכל מי שרוצה לדאוג ליקרים לו – בין אם אתה הורה לילדים, בעל משכנתא, עצמאי או דואג להורים מבוגרים. הביטוח מבטיח שמי שתלוי בהכנסותיך יוכל להמשיך את חייו ברווחה כלכלית, גם לאחר לכתך.<br/>
              בפגישה אישית, נבצע עבורך תכנון מקיף כדי לקבוע את סכום הביטוח האופטימלי עבורך – כך שתוכל להיות בטוח שמשפחתך תישאר מוגנת כלכלית, בכל מצב. <br/>
            </Paragraph>
          </div>}
          {id === '3' && <div className="right">
            <Title className="title">מחלות קשות</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>              ביטוח מחלות קשות – רשת ביטחון כלכלית ברגעים החשובים ביותר</b><br/>
              במקרה של אבחון מחלה קשה, ההתמודדות אינה רק רפואית אלא גם כלכלית. ביטוח מחלות קשות מעניק פיצוי כספי מיידי למבוטח, אשר מאפשר לו ולמשפחתו להתמקד בהחלמה ולשמור על יציבות כלכלית בתקופה המאתגרת.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>למה חשוב שיהיה לך ביטוח מחלות קשות?</b><br/>

              &emsp; ✅ פיצוי כספי מיידי – קבלת סכום חד-פעמי עם אבחון המחלה, ללא צורך בהצגת הוצאות רפואיות.<br/>
              &emsp; ✅ שמירה על רמת החיים – מסייע בכיסוי ההוצאות הנלוות לטיפולים רפואיים, שיקום ואובדן הכנסה.<br/>
              &emsp; ✅ תמיכה למשפחה – מסייע לקרובים הנאלצים ללוות את החולה ולהפחית עומסים כלכליים נוספים.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה הביטוח מכסה?</b><br/>

              &emsp; ✔ פיצוי במקרה של אבחון אחת מ-43 מחלות קשות המפורטות בפוליסה<br/>
              &emsp; ✔ סיוע בהתמודדות כלכלית עם אובדן הכנסה<br/>
              &emsp; ✔ אפשרות לשימוש בפיצוי לכל צורך – טיפולים רפואיים, שיקום, התאמת דיור, סיוע משפחתי ועוד<br/><br/>

              היתרון הגדול של הביטוח? אין צורך להוכיח הוצאות – הכסף נכנס לחשבונך באופן מיידי עם גילוי המחלה, ומאפשר לך להתמודד עם האתגר בצורה הטובה ביותר.<br/>

              העתיד לא צפוי, אבל אפשר להיערך אליו מראש. ביטוח מחלות קשות נותן לך ולמשפחתך שקט נפשי וביטחון כלכלי – ברגעים שבהם זה הכי חשוב.
            </Paragraph>
          </div>}
          {id === '4' && <div className="right">
            <Title className="title">מדיהו</Title>
            <Paragraph className={'description'}>
              שירות "מדיהו" הוא שירות המיועד לעזור למבוטחים בתחום הביטוח הפנסיוני והבריאות לממש את זכויותיהם בצורה נוחה ויעילה. השירות מספק סיוע בהבנת תהליכים בירוקרטיים, הגשת תביעות, קבלת פיצויים, ודאגה לכך שהמבוטח יקבל את כל הזכויות המגיעות לו לפי תנאי הפוליסות השונות.<br/>

              בנוסף, שירות מדיהו מאפשר למבוטחים להיעזר במומחים בביטוח ובתביעות מול חברות הביטוח במטרה לייעל את כל התהליך, לקצר זמנים ולהשיג את התוצאות הטובות ביותר עבורם.
            </Paragraph><br/>
            <Title level={4}>לחץ על התמונה לפרטים נוספים</Title>
            <br/>
            <a href='https://mediwhobestcare.com/matan-solomon-2/' target='_blank' rel="noreferrer" aria-label="מדיהו">
              <img src={medi} alt='מדיהו'/>
            </a>
          </div>}
          {id === '5' && <div className="right">
            <Title className="title">פנסיה</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה היא קרן פנסיה? קרן פנסיה – הביטחון הכלכלי שלך לעתיד</b><br/>

              קרן פנסיה היא חיסכון לטווח ארוך שנועד להבטיח לך הכנסה חודשית קבועה לאחר הפרישה מהעבודה. עבור כל אדם העובד ומפרנס את עצמו ואת משפחתו, קרן הפנסיה היא רשת ביטחון כלכלית הכרחית, המעניקה תמיכה גם במקרים בלתי צפויים כמו פגיעה בכושר עבודה או פטירה.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה מעניקה קרן הפנסיה?</b><br/>

              &emsp; ✅ קצבת זקנה – הכנסה חודשית קבועה לכל החיים מגיל הפרישה ואילך.<br/>
              &emsp; ✅ קצבת נכות – הגנה במקרה של פגיעה בכושר העבודה, אם נפגעת בשיעור של לפחות 25% ואינך מסוגל לעבוד בעבודתך או בכל עבודה אחרת המתאימה להשכלתך, הכשרתך או ניסיונך, למשך למעלה מ-90 ימים רצופים.<br/>
              &emsp; ✅ קצבת שארים – תשלום חודשי לבני המשפחה במקרה של פטירה, כדי להבטיח את עתידם הכלכלי.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>למה חשוב לדאוג לקרן פנסיה?</b><br/>

              &emsp; ✔ מבטיחה לך הכנסה חודשית קבועה גם לאחר הפרישה<br/>
              &emsp; ✔ כוללת כיסויים ביטוחיים למצבים בלתי צפויים<br/>
              &emsp; ✔ מאפשרת תכנון פיננסי חכם להבטחת רמת חיים יציבה גם בעתיד<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>"פנסיה מקיפה" </b>
              כוללת את כל הכיסויים הללו ומספקת לך ולמשפחתך שקט נפשי וביטחון כלכלי – גם לעתיד הרחוק.
            </Paragraph>
          </div>}
          {id === '6' && <div className="right">
            <Title className="title">גמל</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>קופת גמל – חיסכון חכם וגמיש לעתיד שלך</b><br/>

              קופת גמל היא אפיק חיסכון פיננסי שנועד להבטיח לך צבירת הון לטווח קצר, בינוני או ארוך, בהתאם לצרכים שלך. בניגוד לקרנות פנסיה וביטוחי מנהלים הכוללים רכיב ביטוחי, קופת גמל מתמקדת בחיסכון והשקעה בלבד, תוך אפשרות ליהנות מתשואות הקופה בהתאם לביצועי השוק.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>איך זה עובד?</b><br/>

              &emsp; ✅ ניהול מקצועי – הכספים מנוהלים על ידי בתי השקעות או חברות ביטוח מובילות.<br/>
              &emsp; ✅ תשואה מצטברת – כל חוסך נהנה מהרווחים של הקופה בהתאם לחלק היחסי שלו.<br/>
              &emsp; ✅ שקיפות מלאה – ניתן לעקוב בזמן אמת אחר היתרה, ההפקדות, דמי הניהול והתשואות.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>סוגי קופות גמל נפוצות</b><br/>

              &emsp; ✔ קופת גמל לחיסכון – חיסכון גמיש לטווח בינוני וארוך, עם אפשרות למשיכה בהתאם לתנאים שנקבעו.<br/>
              &emsp; ✔ קופת גמל להשקעה – מאפשרת הפקדות חודשיות או חד-פעמיות, עם נזילות גבוהה ואפשרות למשיכה בכל עת.<br/>
              &emsp; ✔ קרן השתלמות – מסלול חיסכון פטור ממס לטווח קצר (6 שנים), המתאים לשכירים ולעצמאים.<br/><br/>

              קופות הגמל מאפשרות לך לבנות בסיס כלכלי חזק לעתיד תוך גמישות מקסימלית והתאמה אישית לאופי ההשקעה שלך.
            </Paragraph>
          </div>}
          {id === '7' && <div className="right">
            <Title className="title">קרן השתלמות</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>קרן השתלמות – חיסכון גמיש עם יתרונות מיסוי משמעותיים</b><br/>

              קרן השתלמות היא מוצר חיסכון פנסיוני לטווח קצר עד בינוני (3-6 שנים), המיועד למימון השתלמויות, הכשרה מקצועית או לכל מטרה אחרת. הקרן מיועדת לשני סוגי חוסכים:<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>עמית שכיר</b> – המעסיק מפריש בעד העובד חלק מההפקדה, והעובד עצמו מפריש לפחות שליש מהסכום.<br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>עמית עצמאי </b>– הפקדותיו נעשות כנגד הכנסה מעסק או משלח יד.<br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>יתרונות קרן השתלמות</b><br/>

              &emsp; ✅ חיסכון גמיש לכל מטרה – בסיום התקופה (6 שנים), ניתן למשוך את הכספים או להשאירם להמשך חיסכון.<br/>
              &emsp; ✅ הטבות מס בשלב ההפקדה – עבור עמית שכיר, חלק מהמעסיק בהפקדה פטור ממס בתלוש השכר. עבור עמית עצמאי, חלק מההפקדה מוכר כהוצאה לצורך חישוב המס.<br/>
              &emsp; ✅ פטור ממס רווחי הון – רווחים ריאליים מההפקדות פטורים ממס עד לתקרת ההפקדה השנתית המותרת בחוק.<br/>
              &emsp; ✅ תמהיל השקעות מותאם אישית – ניתן להתאים את הקרן לרצונותיך ולצרכים האישיים שלך, עם אפשרות לבחור בתמהיל השקעות המותאם לפרופיל הסיכון שלך.<br/><br/>

              קרן השתלמות היא כלי חיסכון מצוין לשכירים ולעצמאים כאחד, עם יתרונות מיסוי ואופציה לחיסכון לטווח הקצר או הארוך. תמהיל השקעות ייחודי המותאם בדיוק לרצונך ולצרכים שלך</Paragraph>
          </div>}
          {id === '8' && <div className="right">
            <Title className="title">גמל להשקעה</Title>
            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>קופת גמל להשקעה – חיסכון גמיש עם יתרונות מס ומשיכה נזילה</b><br/>

              קופת גמל להשקעה מציעה לך את האפשרות לחסוך ולהשקיע בטווחים קצרים, בינוניים וארוכים, תוך שמירה על נזילות הכספים. מדובר באפיק חיסכון גמיש המאפשר לך ליהנות מהשקעות מותאמות אישית, ללא קנסות או מגבלות בהמשך הדרך.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>יתרונות קופת גמל להשקעה</b><br/>

              &emsp; ✅ נזילות מלאה – הכספים בקופה נזילים וניתנים למשיכה בכל עת, בסכום חד-פעמי או בתשלומים, ללא קנס.<br/>
              &emsp; ✅ דחיית תשלום מס – תשלום מס על רווחי ההון נדחה עד למועד משיכת הכספים.<br/>
              &emsp; ✅ פטור ממס על רווחי הון – אם תמשוך את הכספים לאחר גיל פרישה כקצבה חודשית, תהיה זכאי לפטור ממס על רווחי ההון.<br/>
              &emsp; ✅ ניוד חופשי – ניתן להעביר כספים בין קופות גמל שונות ובין מסלולי השקעה ללא קנסות וללא תשלום מס.<br/>
              &emsp; ✅ הלוואה בתנאים נוחים – ניתן לקחת הלוואה של עד 80% מהחיסכון הצבור בקופה, עם תקופת החזר מקסימלית של 7 שנים (כפוף לאישור החברה).<br/>
              &emsp; ✅ גמישות בהפקדות – ניתן להפקיד סכום חד-פעמי או תשלומים (תקרת ההפקדה השנתית עד 72,000 ₪ לכל אחד מבני המשפחה).<br/><br/>

              קופת גמל להשקעה היא פתרון מצוין לחיסכון גמיש שייתן לך שליטה מלאה על הכספים, עם יתרונות מס משמעותיים ונזילות גבוהה.
            </Paragraph>
          </div>}
          {id === '9' && <div className="right">
            <Title className="title">פוליסת חסכון</Title>

            <Paragraph className='description'>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>פוליסת חיסכון – חיסכון גמיש עם תשואות נאות וניהול מקצועי</b><br/>

              פוליסת חיסכון היא תוכנית חיסכון והשקעה שמפוקחת על ידי רשות שוק ההון, ומוצעת על ידי חברות ביטוח. מדובר במוצר שמאפשר חיסכון בטווחים שונים תוך שמירה על נזילות הכספים. החיסכון נועד לאנשים המעוניינים להשקיע את כספיהם הפנויים ולהשיג תשואות נאות, תחת ניהול מקצועי של גופי ההשקעה השונים.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>יתרונות פוליסת חיסכון</b><br/>

              &emsp;✅ נזילות מלאה – ניתן למשוך את הכספים בכל עת, חד-פעמי או באופן חלקי, ללא קנסות. אפשרות למשיכה חודשית כקצבה (אנונה).<br/>
              &emsp;✅ פיזור סיכונים רחב – השקעה בשוק ההון ובנכסים לא סחירים כגון נדל"ן מניב, תשתיות וקרנות פרטיות, מה שמפחית את הרגישות לתנודות השוק.<br/>
              &emsp;✅ מעבר חופשי בין מסלולים – המעבר בין מסלולי השקעה לא ייחשב כמכירה, כך שלא תידרשו לשלם מס רווחי הון, כמו שנדרש בקרנות נאמנות.<br/>
              &emsp;✅ שקיפות מלאה – המידע האישי זמין באתר החברה, כולל נתונים חודשיים על ביצועי ההשקעות.<br/>
              &emsp;✅ ניהול השקעות מקצועי – חברות הביטוח ומנהלי ההשקעות בבתי השקעות מנוסים מנהלים את הכספים בצורה מקצועית כדי להשיג תשואות גבוהות.<br/>
              &emsp;               ✅ דמי ניהול אטרקטיביים – דמי ניהול נמוכים של כ-0.8% בשנה, בהשוואה לקרנות נאמנות או השקעות בבנקים, שבהן דמי הניהול גבוהים יותר.<br/><br/>

              פוליסת חיסכון היא אפיק השקעה מצוין למי שמחפש נזילות, תשואות טובות, ופיזור סיכון רחב תוך שמירה על עלויות ניהול נמוכות.
            </Paragraph>
          </div>}
         {/* {id === '10' && <div className="right">
            <Title className="title">השקעות אלטרנטיוביות</Title>
            <Paragraph className='description'>מכשירים פיננסיים אלטרנטיביים מפזרים את הסיכונים בתיק ההשקעות, מאפשרים להשקיע בתחומים שהיו
              פתוחים בעבר רק למשקיעים גדולים ולרוב הם בעלי פוטנציאל תשואה גבוה יותר מכפי שניתן למצוא בשוק ההון
            </Paragraph>
            <br/>
            <Paragraph className='description'>ביניהם אפשר למצוא נדל"ן - בישראל או מעבר לים, קרנות פרייבט אקוויטי, מטבעות דיגיטליים,
              סחורות, חברות המבצעות עבודות תשתיות, חוב פרטי, קרנות גידור, חפצי אמנות ועוד.</Paragraph>
          </div>}*/}
          {id === '11' && <div className="right">
            <Title className="title">ביטוח רכב</Title>
            <Title className="sub-title" level={3}>ביטוח חובה</Title>
            <Paragraph className='description'>
              ביטוח חובה הוא דרישה חוקית במדינת ישראל לכל רכב מנועי. הפוליסה מכסה נזקי גוף בלבד אשר נגרמים לנהג, נוסעים ברכב והולכי רגל, כתוצאה מהשימוש ברכב המנועי.<br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה מכסה הפוליסה?</b><br/>
              פיצוי על מקרה מוות או חבלה בגופו של אדם כתוצאה משימוש ברכב.
            </Paragraph><br/>
            <Title className="sub-title" level={3}>ביטוח צד ג'</Title>
            <Paragraph className='description'>
              ביטוח צד ג' מכסה את הנזק שיגרם לרכוש של צד שלישי במקרה של תאונה, אם נגרם נזק לרכב אחר או רכוש אחר.<br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה מכסה הפוליסה?</b><br/>
              הפוליסה מכסה את המבוטח עד לגבול האחריות הנקוב בפוליסה עבור נזק לרכוש של צד ג' שנגרם על ידי הרכב המבוטח.
            </Paragraph><br/>
            <Title className="sub-title" level={3}>ביטוח מקיף</Title>
            <Paragraph className='description'>
              ביטוח מקיף מכסה את הרכב המבוטח במקרה של נזק תאונתי לרכב, גניבה, אש, נזק בזדון ועוד.<br/><br/>

            <b style={{fontSize: 18, fontWeight: 'bolder'}}>  מה מכסה הפוליסה?</b><br/>

            &emsp; ✅ החזר הוצאות תיקון לאחר תאונה, התנגשות או התהפכות.<br/>
            &emsp; ✅ כיסוי לנזקי גניבה, כולל גניבת רכב או ניסיון גניבה.<br/>
            &emsp; ✅ כיסוי לנזקי טבע כמו אש, ברק, סערה או שיטפון.<br/>
            &emsp; ✅ כיסוי לנזקי רכוש שנגרמו לצד ג'.<br/>
            &emsp; ✅ ביטוח ירידת ערך לרכב לאחר תאונה.<br/>
            &emsp; ✅ הוצאות משפטיות במקרה של צורך בהגנה משפטית.<br/>

            </Paragraph>
            <br/>
            <br/>
            <br/>
            <Title className="title">ביטוח דירה</Title>
            <Paragraph className={'description'}>
              ביטוח דירה מחולק לביטוח מבנה ותכולה ומספק כיסוי לנזקים שעלולים להיגרם לדירה ו/או לתכולת הדירה, כגון שריפה, גניבה, סערה, שיטפון ועוד.<br/><br/>
              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה מכסה הפוליסה?</b><br/>

              &emsp; ✅ כיסוי לנזקי רכוש במבנה הדירה ולתכולה.<br/>
              &emsp; ✅ כיסוי לנזקים בעקבות סיכונים כמו שריפה, שיטפון, פריצה, גניבה, סערות, התפוצצות, ועוד.<br/>
              &emsp; ✅ ביטוח אחריות חוקית כלפי צד שלישי (כמו אורחים שנפגעים בדירה).<br/>
              &emsp; ✅ ביטוח חבות מעבידים לכיסוי נזקי עובדים במקרה של פגיעה בזמן עבודה בבית.<br/><br/>
              ביטוח דירה מכסה את רכושך במקרה של נזקים שעלולים להותיר אותך עם הפסדים כספיים משמעותיים, ואף למנוע פגיעות כלפי צדדים שלישיים.
          </Paragraph>
          </div>}
          {id === '12' && <div className="right">
            <Title className="title">ביטוח נסיעות לחו״ל</Title>
            <Paragraph className='description'>
              ביטוח נסיעות לחו"ל נועד להעניק כיסוי רפואי ופתרונות נוספים במקרה של בעיות בריאותיות או פגיעות בזמן שהותכם בחו"ל. הפוליסה כוללת מגוון כיסויים חשובים, כדי שתהיו מכוסים במגוון מצבים:<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>הכיסויים הכלולים בפוליסה הבסיסית:</b><br/>

              &emsp; ✅ הוצאות רפואיות בחו"ל: כיסוי הוצאות רפואיות במקרה של אשפוז או טיפול רפואי שלא במסגרת אשפוז, כולל כיסוי עבור טיפול במקרים של נגיף הקורונה.<br/>
              &emsp; ✅ הטסה רפואית לישראל: כיסוי במקרה הצורך בהטסה רפואית חזרה לישראל.<br/>
              &emsp; ✅ ספורט אתגרי: כיסוי לפציעות או תאונות במהלך פעילות ספורטיבית אתגרית.<br/>
              &emsp; ✅ ספורט חורף: כיסוי לפציעות הנגרמות בעת פעילויות ספורט חורף.<br/>
              &emsp; ✅ כבודה: כיסוי לנזקים או אובדן של כבודת הנסיעה.<br/>
              &emsp; ✅ ביטול וקיצור נסיעה: כיסוי הוצאות במקרה של ביטול או קיצור הנסיעה מסיבות רפואיות או אחרות.<br/>
              &emsp; ✅ גניבה של ציוד אלקטרוני: כיסוי במקרה של גניבת מצלמה, טלפון נייד, מחשב נייד או ציוד אחר.<br/>
              &emsp; ✅ כיסוי למצב רפואי קיים: כיסוי למי שיש לו מצב רפואי קיים, בכפוף לתנאים הספציפיים בפוליסה.<br/>
              &emsp; ✅ הוצאות רפואיות בישראל: כיסוי הוצאות רפואיות שנעשו בישראל, במקרים מסוימים.<br/><br/>
              הפוליסה מעניקה כיסוי רחב במהלך השהות בחו"ל, והיא יכולה להקל מאוד על ההתמודדות עם הוצאות רפואיות או תאונות, תוך שהיא מספקת תמיכה וייעוץ 24/7</Paragraph>
            <br/>
            <a href='https://purchase.passportcard.co.il/existing?AffiliateId=U%2Bx%2Bw9O0ih%2F1SJ9bjiPZeA%3D%3D'
               target='_blank' rel="noreferrer" aria-label="רכישת פספורטכארד">
              <Title className="sub-title" level={3}>לרכישה ביטוח פספורטכארד</Title>
              <br/>
              <img src='https://distributor-api.passportcard.co.il/0db2de1e-14f0-4e7d-8f98-130aeae940cd.jpg'
                   alt='פספורטכארד'/>
            </a>
            <br/>
            <a
              href='https://digital.harel-group.co.il/travel-policy?guid=bee1d408-c6a7-410e-b4ee-ac690224bdd3&an=047879'
              target='_blank' rel="noreferrer" aria-label="רכישת ביטוח הראל">
              <Title className="sub-title" level={3}>לרכישה ביטוח הראל</Title>
              <br/>
              <img src={harel} alt='הראל' width='300'/>
            </a>

            <br/>
            <a
              href='https://www.clalbit.co.il/travelingisurance/claltravel/?txtCompID=1&txtItemID=b227f04b-06af-13af-e053-0beb14ac5c74#/'
              target='_blank' rel="noreferrer" aria-label="רכישת ביטוח הראל">
              <Title className="sub-title" level={3}>לרכישה ביטוח הראל</Title>
              <br/>
              <img src={calalbit} alt='כלל ביט' width='300'/>
            </a>
          </div>}
          {id === '13' && <div className="right">
            <Title className="title">החזרי מס</Title>

            <Title className="sub-title" level={3}>מה זה החזר מס?</Title>
            <Paragraph className="description">
              החזר מס הוא סכום כסף שמחזירים למס הכנסה אם שילמתם יותר מדי מס במהלך השנה. לעיתים, אנשים לא מודעים לזכאותם להחזר מס, במיוחד שכירים.
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>למי מגיע החזר מס?</Title>
            <Paragraph className='description'>
              החזרי מס מגיעים לשני סוגים עיקריים: שכירים ועצמאיים. בעוד שעצמאיים מגישים את הוצאותיהם באופן קבוע, שכירים לא תמיד מודעים לכך שהם עשויים להיות זכאים להחזר מס.
            </Paragraph>
            <br/>


            <Title className="sub-title" level={3}>סיבות נפוצות לזכאות להחזרי מס לשכירים:</Title>
            <Paragraph className='description'>
              &emsp; ✅ החלפת עבודה או אי רציפות תעסוקתית: כאשר עובדים מחליפים מקום עבודה במהלך השנה, הם עשויים לשלם יותר מדי מס.<br/>
              &emsp; ✅ חיילים משוחררים: חיילים משוחררים עשויים להיות זכאים לנקודות זיכוי שלא תמיד ממומשות.<br/>
              &emsp; ✅ אמהות טריות: נשים אחרי לידה עשויות להיות זכאיות להחזרי מס בשל חופשות לידה והיעדרות מהעבודה.<br/>
              &emsp; ✅ מורים: מורים שיצאו לשנת שבתון או קיבלו מענקים עשויים להיות זכאים להחזרי מס.<br/>
              &emsp; ✅ עולים חדשים: עולים חדשים עשויים לשלם מס יתר בשל נקודות זיכוי שלא הוזנו כראוי.<br/>
              &emsp; ✅ רופאים: רופאים עם מספר מקומות עבודה עשויים לשלם יותר מס ולהיות זכאים להחזר.<br/>
              &emsp; ✅ החזר על ביטוחים: המדינה מעניקה החזר על ביטוחים כמו ביטוח חיים ופנסיה עד סכום מסוים.<br/>
            </Paragraph><br/>

            <Title className="sub-title" level={3}>נקודות זיכוי ומס הכנסה</Title>
            <Paragraph>
              נקודות זיכוי הן הטבה ממס הכנסה שמפחיתה את סכום המס שעליכם לשלם. כל אדם זכאי לנקודות זיכוי על פי קריטריונים שונים, כגון: תושב ישראל, הורות, חיילים משוחררים, עולים חדשים, ועוד. אם המעסיק לא התחשב בנקודות זיכוי שלכם, ייתכן שמגיע לכם החזר מס.

            </Paragraph><br/>

            <Title className="sub-title" level={3}>איך תדעו אם מגיע לכם החזר?</Title>
            <Paragraph>
              באתר ניתן לבדוק אם אתם זכאים להחזר ולהגיש בקשה למס הכנסה בצורה פשוטה ומהירה.
            </Paragraph><br/>
          </div>}
          {id === '14' && <div className="right">
            <Title className="title">יעוץ משכנתא</Title>
            <Paragraph className='description'>
              לקיחת משכנתא לא חייבת להיות תהליך מסובך! אנחנו כאן כדי להגשים לכם את חלום רכישת הדירה, עם ליווי צמוד של מומחים בתחום.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה אנחנו מציעים?</b><br/>

              &emsp; ✅ ייעוץ מקצועי למשכנתאות: קבלת משכנתא חדשה, שיפור דיור, מחזור משכנתאות, משכנתא הפוכה ואיחוד הלוואות.<br/>
              &emsp; ✅ תנאים מותאמים אישית: צוות המומחים שלנו ימצא את המשכנתא שמתאימה בדיוק לצרכים שלכם, תוך חיסכון של אלפי שקלים בריביות מיותרות.<br/>
              &emsp; ✅ ליווי צמוד לאורך כל התהליך: אנחנו כאן כדי להקל עליכם ולהבטיח שתהנו מתנאים מעולים ומשכנתא שמתאימה לכם.<br/><br/>
              הצוות שלנו מלווה אתכם במקצועיות ובאמינות על מנת שתוכלו לצאת לדרך החדשה שלכם עם שקט נפשי ובלי הפתעות.

            </Paragraph>
          </div>}
          {id === '15' && <div className="right">
            <Title className="title">יעוץ ותכנון פרישה</Title>
            <Paragraph className='description'>
              תכנון פרישה הוא שלב קריטי לקראת המעבר לתקופת חיים חדשה, ויש צורך בהיערכות מוקדמת הן מבחינה כלכלית והן מבחינה נפשית. תהליך זה נועד ללוות את הפורש ומשפחתו, החל מהשלב הקדם-פרישה ועד להסדרת השימוש בכספי החיסכון הפנסיוני וכספי הפיצויים.<br/><br/>

              <b style={{fontSize: 18, fontWeight: 'bolder'}}>מה כולל תהליך תכנון הפרישה?</b><br/>

              &emsp; ✅ הערכת מצב אישי: תכנון שמתחשב בשינויים לאורך השנים, כולל לימודים, מציאת עבודה, שינויי מצב משפחתי (רווקות, נישואין, גרושין או אלמנות).<br/>
              &emsp; ✅ תכנון כלכלי לטווח ארוך: בניית תוכנית פרישה שתתאים לצרכים האישיים של הפורש לאורך כל תקופת הפרישה, שהיא לא אירוע חד-פעמי אלא תהליך שנמשך לעשרות שנים.<br/>
              &emsp; ✅ העברה בין-דורית: הבטחת העברה נכונה של המשאבים הפנסיוניים והכנסות לבני הזוג והילדים.<br/><br/>
              תפקידנו כמתכנני פרישה הוא לסייע לכם להבין את המשמעויות הכלכליות של הפרישה, ולהיערך לתקופה החדשה עם שקט נפשי ותכנון מושכל.
            </Paragraph>
          </div>}
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default OurServices;
