import React, {useState} from 'react';
import {Typography, Row, Col, Input, Button, Space} from 'antd';

import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import MainTitle from "../components/MainTitle";
import {ArrowLeftOutlined, FacebookOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {contactUsService} from "../services";

const {Title, Text, Paragraph} = Typography;
const {TextArea} = Input;

const ContactUsPage = () => {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');

  return (
    <div id="contact-us-page">
      <div className="main"/>
      <MainTitle title={"יצירת קשר"} subtitle={"יצירת קשר"}/>
      <div className="contact-us-container">
        <div className="contact">
          <div className="right">
            <Title level={1}>מתן סולומון - פתרונות ביטוח ופיננסים</Title>
            <Paragraph className="description">
              בן 33, נשוי למאי ואבא לשניים.<br/>
              בעל ניסיון של למעלה מעשור בתחום הביטוח והפיננסים.<br/>
              מחזיק ברישיון סוכן ביטוח פנסיוני ואלמנטרי מטעם משרד האוצר וכן בתעודת יועץ משכנתאות.<br/>
              פועל בתחום הביטוח, השקעות והפיננסים.<br/>
              כמו כן מכהן כחבר בית הדין של לשכת סוכני הביטוח.<br/>
            </Paragraph>
            <Space direction="vertical" align="start" style={{width: '100%', fontSize: 18}}>
              <Text style={{fontSize: 18}}>ההסתדרות 21, חיפה</Text>
              <a href="tel:0548072575" aria-label="טלפון">
                <span className="elementor-icon-list-text"> 054-807-2575</span>
              </a>
              <a href="mailto://matan@solomon-inf.co.il" aria-label="אימייל">
                <span className="elementor-icon-list-text"> matan@solomon-inf.co.il </span>
              </a>
            </Space>
            <Space size="middle" style={{direction: 'ltr', fontSize: 25, padding: 10,}}>
              <a href="https://wa.me/+972548072575" target="_blank" style={{color: '#000'}} rel="noreferrer"
                 title='whatapp' aria-label="whatapp">
                <WhatsAppOutlined/>
              </a>
              <a href="https://www.facebook.com/matansolomonins" id="facebook-contact-us" target="_blank" style={{color: '#000'}}
                 rel="noreferrer" title='facebook' aria-label="facebook">
                <FacebookOutlined/>
              </a>
              {/*   <a href="" target="_blank" style={{color: '#000'}}>
              <InstagramOutlined/>
            </a>*/}
            </Space>
          </div>
          <div className="left">
            <Row gutter={16}>
              <Col xl={24} lg={24} md={24} xs={24}>
                <Title level={2}> השאירו פרטים</Title>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Input size="large" placeholder="שם מלא" title='שם מלא'
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                         setFullName(e.target.value);
                       }}/>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Input size="large" placeholder="מס׳ טלפון" title='טלפון'
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                         setPhone(e.target.value);
                       }}/>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Input size="large" placeholder="אימייל" title='אימייל'
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                         setEmail(e.target.value);
                       }}/>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Input size="large" placeholder="נושא הפנייה" title='נושא הפנייה'
                       onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                         setSubject(e.target.value);
                       }}/>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <TextArea rows={4} placeholder="פרטי הפנייה" maxLength={300} title="פרטי הפנייה"
                          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setMsg(e.target.value);
                          }}/>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button icon={<ArrowLeftOutlined/>} size="large" onClick={() => {
                  if (fullName.trim() !== '' && phone.trim() !== '' && email.trim() !== '') {
                    contactUsService({fullName, phone, email, subject, msg}).then(r => {
                    })
                  }
                }}>
                  שליחה
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default ContactUsPage;
